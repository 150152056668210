.dropdown {
    position: relative;
}

.menu {
    position: absolute;

    list-style-type: none;
    margin: 5px 0;
    padding: 0;

    border: 1px solid grey;
    width: 144px;
}

.menu > li {
    margin: 0;

    background-color: rgb(17, 18, 23);
}

.menu > li:hover,
.dropdown:hover {
    background-color: rgb(35, 37, 46);
}

.menu > li > button {
    width: 100%;
    height: 100%;
    text-align: left;

    background: none;
    color: inherit;
    border: none;
    padding: 5px;
    padding-left: 10px;
    margin: 0;
    font: inherit;
    cursor: pointer;
}

@media (max-width: 755px) {
    .menu {
        width: 85px;
    }
}
