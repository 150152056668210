.pages {
    margin: 1rem;
    text-align: center;
    flex: 1;
    order: 1;
    z-index: 0;
}

.noValidData {
    color: red;
    font-weight: bold;
    font-size: larger;
}
