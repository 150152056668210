.App {
    margin: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(17, 18, 23);
    color: white;
}

input {
    color: white;
}

svg {
    stroke: white;
}

* {
    box-sizing: border-box !important;
}
