.dienst_table {
    width: 35%;
    min-width: 718px;
    border-collapse: collapse;
    border-spacing: 0px 5px;
    margin-left: auto;
    margin-right: auto;
    table-layout: fixed;
}

@media (max-width: 755px) {
    .dienst_table {
        min-width: 0px;
        width: 100%;
    }

    /* Hide Car if under 640px */
    .car_header {
        display: none;
    }
    .car {
        display: none;
    }

    /* Hide KD, RD, NA if under 590px */
    .kd_header {
        display: none;
    }
    .kd {
        display: none;
    }
    .rd_header {
        display: none;
    }
    .rd {
        display: none;
    }
    .na_header {
        display: none;
    }
    .na {
        display: none;
    }

    /* Hide TF if under 590px */
    .tf_header {
        display: none;
    }
    .tf {
        display: none;
    }

    /* Hide delete under 590px */
    .deleteData_header {
        display: none;
    }
    .deleteData {
        display: none;
    }

    .dienst_table > .dienst_header > tr > .datum_header {
        width: 25%;
    }
    .dienst_table > .dienst_header > tr > .daytime_header {
        width: 20%;
    }
    .dienst_table > .dienst_header > tr > .type_header {
        width: 15%;
    }
    .dienst_table > .dienst_header > tr > .sumcalls_header {
        width: 15%;
    }
    .dienst_table > .dienst_header > tr > .duration_header {
        width: 10%;
    }
    .dienst_table > .dienst_header > tr > .zug_header {
        width: 15%;
    }
}
