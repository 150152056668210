footer {
    text-align: center;
    width: 100%;
    border-top: 1px solid white;
    font-size: small;
    order: 5;
    color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.cached {
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
}

.copyright {
    width: calc(100% / 3);
    display: flex;
    justify-content: flex-start;
}

.social {
    text-decoration: none;
    width: calc(100% / 3);
    display: flex;
    justify-content: flex-end;
}

.links {
    text-decoration: none;
    color: inherit;
    padding: 5px;
}
