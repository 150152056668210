.container_timeline {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1.9fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'Header_timeline'
        'Months_timeline';
    border: 3px solid #ccccdc12;
    margin: 10px;
    border-collapse: collapse;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.Header_timeline {
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'Header_year_timeline Header_days_timeline';
    grid-area: Header_timeline;
    border-bottom: 3px solid #ccccdc12;
    background-color: #121518;
}

.TimelineHeaderDays {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
}

.TimelineMonths {
    background-color: #121518;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.'
        '.';
}

.TimelineMonth {
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: '. .';
}

.TimelineDays {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: '. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
}

.TimelineDay {
    border: 1px solid #ccccdc12;
}

.rotatePhone {
    display: none;
}

@media (max-width: 580px) {
    .rotatePhone {
        display: block;
    }

    .container_timeline {
        display: none;
    }
}
