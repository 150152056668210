.dienst {
    background-color: #181b1f;
    border: 1px solid #ccccdc12;
    border-radius: 3px;
    box-shadow: none;
}

.dienst > td {
    vertical-align: middle;
    text-align: center;
}

.active_dienst {
    background-color: #4f5153;
    border: 1px solid #ccccdc12;
    border-radius: 3px;
    box-shadow: none;
}

.deleteData {
    width: 100%;
    height: 100%;
    padding: 0px;
}

.deleteDataButtonDiv {
    height: 100%;
    width: 100%;
    display: block;
}

.deleteDataButton {
    display: block;

    width: 100%;
    height: 100%;
    padding: 0px;

    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: none;

    cursor: pointer;
}
