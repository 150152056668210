.container_addData {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'Datum_addData'
        'Daytime_addData'
        'Type_addData'
        'Calls_addData'
        'Duration_addData'
        'Zug_addData'
        'Car_addData'
        'TF_addData'
        'Confirm_addData';
    width: 100%;
    max-width: 718px;
    height: 820px;
    margin: 0 auto;
    border: 1px solid #ccccdc12;
}

/* Button Font Size */
.container_addData > div > div > div > div > button {
    font-size: x-large;
}

/* Text Font Size */
.container_addData > div > div {
    font-size: large;
}

/*
*███████████████████████████████████████████████████████████
* _____          _                     
* |  __ \        | |                    
* | |  | |  __ _ | |_  _   _  _ __ ___  
* | |  | | / _` || __|| | | || '_ ` _ \ 
* | |__| || (_| || |_ | |_| || | | | | |
* |_____/  \__,_| \__| \__,_||_| |_| |_|
*███████████████████████████████████████████████████████████
*/

.Datum_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'DatumText_addData DatumInput_addData';
    grid-area: Datum_addData;
    border: inherit;
}

.DatumText_addData {
    grid-area: DatumText_addData;
}

.DatumInput_addData {
    grid-area: DatumInput_addData;
    margin: auto;
    width: 75%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DatumInput_addData > div > div {
    font-size: xx-large;
}

/*
*███████████████████████████████████████████████████████████
*  _____                 _    _                  
* |  __ \               | |  (_)                 
* | |  | |  __ _  _   _ | |_  _  _ __ ___    ___ 
* | |  | | / _` || | | || __|| || '_ ` _ \  / _ \
* | |__| || (_| || |_| || |_ | || | | | | ||  __/
* |_____/  \__,_| \__, | \__||_||_| |_| |_| \___|
*                  __/ |                         
*                 |___/                          
*███████████████████████████████████████████████████████████
*/

.Daytime_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'DaytimeText_addData DaytimeInput_addData';
    grid-area: Daytime_addData;
    border: inherit;
}

.DaytimeText_addData {
    grid-area: DaytimeText_addData;
}

.DaytimeInput_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'DaytimeDay_addData DaytimeNight_addData';
    grid-area: DaytimeInput_addData;
}

.DaytimeDay_addData {
    grid-area: DaytimeDay_addData;
    margin: auto 0;
    height: 75%;
}

.DaytimeDay_addData > div {
    height: 100%;
    width: 75%;
    margin: 0 auto;
}

.DaytimeDay_addData > div > button {
    height: 100%;
    width: 100%;
}

.DaytimeNight_addData {
    grid-area: DaytimeNight_addData;
    margin: auto 0;
    height: 75%;
}

.DaytimeNight_addData > div {
    height: 100%;
    width: 75%;
    margin: 0 auto;
}

.DaytimeNight_addData > div > button {
    height: 100%;
    width: 100%;
}

/*
*███████████████████████████████████████████████████████████
*  _______                  
* |__   __|                 
*    | | _   _  _ __    ___ 
*    | || | | || '_ \  / _ \
*    | || |_| || |_) ||  __/
*    |_| \__, || .__/  \___|
*         __/ || |          
*        |___/ |_|          
*███████████████████████████████████████████████████████████
*/

.Type_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'TypeText_addData TypeInput_addData';
    grid-area: Type_addData;
    border: inherit;
}

.TypeText_addData {
    grid-area: TypeText_addData;
}

.TypeInput_addData {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'TypeKTW_addData TypeRTW_addData TypeAMB_addData';
    grid-area: TypeInput_addData;
}

.TypeKTW_addData {
    grid-area: TypeKTW_addData;
    height: 75%;
    margin: auto 0;
}

.TypeKTW_addData > div {
    height: 100%;
    width: 75%;
    margin: 0 0 auto 22px;
}

.TypeKTW_addData > div > button {
    height: 100%;
    width: 100%;
}

.TypeRTW_addData {
    grid-area: TypeRTW_addData;
    height: 75%;
    margin: auto 0;
}

.TypeRTW_addData > div {
    height: 100%;
    width: 75%;
    margin: 0 auto;
}

.TypeRTW_addData > div > button {
    height: 100%;
    width: 100%;
}

.TypeAMB_addData {
    grid-area: TypeAMB_addData;
    height: 75%;
    margin: auto 0;
}

.TypeAMB_addData > div {
    height: 100%;
    width: 75%;
    margin: 0 0 auto 7px;
}

.TypeAMB_addData > div > button {
    height: 100%;
    width: 100%;
}

/*
*███████████████████████████████████████████████████████████
*   _____        _  _      
*  / ____|      | || |     
* | |      __ _ | || | ___ 
* | |     / _` || || |/ __|
* | |____| (_| || || |\__ \
*  \_____|\__,_||_||_||___/
*███████████████████████████████████████████████████████████
*/

.Calls_addData {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas:
        'KT_addData'
        'RD_addData'
        'NA_addData';
    grid-area: Calls_addData;
    border: inherit;
}
.KT_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'KTText_addData KTInput_addData';
    grid-area: KT_addData;
}
.KTText_addData {
    grid-area: KTText_addData;
}
.KTInput_addData {
    grid-area: KTInput_addData;
    margin: auto;
    width: 82.5%;
    height: 75%;
}
.KTInput_addData > input {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: x-large;
    border: 1px solid grey;
    background-color: rgb(17, 18, 23);
}

.RD_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'RDText_addData RDInput_addData';
    grid-area: RD_addData;
}
.RDText_addData {
    grid-area: RDText_addData;
}
.RDInput_addData {
    grid-area: RDInput_addData;
    margin: auto;
    width: 82.5%;
    height: 75%;
}
.RDInput_addData > input {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: x-large;
    border: 1px solid grey;
    background-color: rgb(17, 18, 23);
}

.NA_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'NAText_addData NAInput_addData';
    grid-area: NA_addData;
}
.NAText_addData {
    grid-area: NAText_addData;
}
.NAInput_addData {
    grid-area: NAInput_addData;
    margin: auto;
    width: 82.5%;
    height: 75%;
}
.NAInput_addData > input {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: x-large;
    border: 1px solid grey;
    background-color: rgb(17, 18, 23);
}

/*
*███████████████████████████████████████████████████████████
*  _____                      _    _               
* |  __ \                    | |  (_)              
* | |  | | _   _  _ __  __ _ | |_  _   ___   _ __  
* | |  | || | | || '__|/ _` || __|| | / _ \ | '_ \ 
* | |__| || |_| || |  | (_| || |_ | || (_) || | | |
* |_____/  \__,_||_|   \__,_| \__||_| \___/ |_| |_|
*███████████████████████████████████████████████████████████
*/

.Duration_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'DurationText_addData DurationInput_addData';
    grid-area: Duration_addData;
    border: inherit;
}

.DurationText_addData {
    grid-area: DurationText_addData;
}

.DurationInput_addData {
    grid-area: DurationInput_addData;
    margin: auto;
    width: 82.5%;
    height: 75%;
}

.DurationInput_addData > input {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: x-large;
    border: 1px solid grey;
    background-color: rgb(17, 18, 23);
}

/*
*███████████████████████████████████████████████████████████
*  ______             
* |___  /             
*    / / _   _   __ _ 
*   / / | | | | / _` |
*  / /__| |_| || (_| |
* /_____|\__,_| \__, |
*                __/ |
*               |___/ 
*███████████████████████████████████████████████████████████
*/

.Zug_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'ZugText_addData ZugInput_addData';
    grid-area: Zug_addData;
    border: inherit;
}

.ZugText_addData {
    grid-area: ZugText_addData;
}

.ZugInput_addData {
    grid-area: ZugInput_addData;
    justify-content: center;
    margin: auto;
    width: 82.5%;
    height: 75%;
}

.ZugInput_addData > input {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: x-large;
    border: 1px solid grey;
    background-color: rgb(17, 18, 23);
}

/*
*███████████████████████████████████████████████████████████
*   _____             
*  / ____|            
* | |      __ _  _ __ 
* | |     / _` || '__|
* | |____| (_| || |   
*  \_____|\__,_||_|   
*███████████████████████████████████████████████████████████
*/

.Car_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'CarText_addData CarInput_addData';
    grid-area: Car_addData;
    border: inherit;
}

.CarText_addData {
    grid-area: CarText_addData;
}

.CarInput_addData {
    grid-area: CarInput_addData;
    margin: auto;
    width: 82.5%;
    height: 75%;
}

.CarInput_addData > input {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: x-large;
    border: 1px solid grey;
    background-color: rgb(17, 18, 23);
}

/*
*███████████████████████████████████████████████████████████
*  _______                                           _   
* |__   __|                                         | |  
*    | | _ __  __ _  _ __   ___  _ __    ___   _ __ | |_ 
*    | || '__|/ _` || '_ \ / __|| '_ \  / _ \ | '__|| __|
*    | || |  | (_| || | | |\__ \| |_) || (_) || |   | |_ 
*    |_||_|   \__,_||_| |_||___/| .__/  \___/ |_|    \__|
*                               | |                      
*                               |_|                      
*███████████████████████████████████████████████████████████
*/

.TF_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'TFText_addData TFInput_addData';
    grid-area: TF_addData;
    border: inherit;
}

.TFText_addData {
    grid-area: TFText_addData;
}

.TFInput_addData {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'TFno_addData TFyes_addData';
    grid-area: TFInput_addData;
}

.TFno_addData {
    grid-area: TFno_addData;
    margin: auto 0;
    height: 75%;
}

.TFno_addData > div {
    height: 100%;
    width: 75%;
    margin: 0 auto;
}

.TFno_addData > div > button {
    height: 100%;
    width: 100%;
}

.TFyes_addData {
    grid-area: TFyes_addData;
    margin: auto 0;
    height: 75%;
}

.TFyes_addData > div {
    height: 100%;
    width: 75%;
    margin: 0 auto;
}

.TFyes_addData > div > button {
    height: 100%;
    width: 100%;
}

/*
*███████████████████████████████████████████████████████████
*   _____                __  _                  
*  / ____|              / _|(_)                 
* | |      ___   _ __  | |_  _  _ __  _ __ ___  
* | |     / _ \ | '_ \ |  _|| || '__|| '_ ` _ \ 
* | |____| (_) || | | || |  | || |   | | | | | |
*  \_____|\___/ |_| |_||_|  |_||_|   |_| |_| |_|
*███████████████████████████████████████████████████████████
*/

.Confirm_addData {
    justify-self: stretch;
    align-self: stretch;
    grid-area: Confirm_addData;
    border: inherit;
    justify-content: center;
    display: flex;
}

.Confirm_addData > button {
    width: 75%;
    height: 75%;
    text-align: center;
    font-size: x-large;
    border: 1px solid grey;
    background-color: rgb(29, 116, 18);
    margin: auto;
    color: white;
    font-weight: bolder;
    cursor: pointer;
}

.Confirm_addData > button:hover {
    background-color: rgb(23, 91, 14);
}

.isActive {
    background-color: #181b1f;
}

/*
*███████████████████████████████████████████████████████████
*  __  __         _      _  _       
* |  \/  |       | |    (_)| |      
* | \  / |  ___  | |__   _ | |  ___ 
* | |\/| | / _ \ | '_ \ | || | / _ \
* | |  | || (_) || |_) || || ||  __/
* |_|  |_| \___/ |_.__/ |_||_| \___|
*███████████████████████████████████████████████████████████
*/

@media (max-width: 718px) {
    [class*='Input'] {
        width: 188px;
    }

    [class*='Input'] > input {
        width: 87.5%;
    }

    .TypeKTW_addData > div {
        margin: 0 0 auto 12px;
    }
    .TypeAMB_addData > div {
        margin: 0 0 auto 4px;
    }

    .DatumInput_addData > div > div {
        font-size: x-large;
    }

    .container_addData > div > div > div > div > button {
        font-size: small;
    }

    .ZugInput_addData > div > div > button {
        font-size: x-large !important;
    }
}
