.dateRangePicker {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.dateRangePickerObject {
    padding-left: 10px;
    padding-right: 10px;
}

.quickButton {
    padding-left: 10px;
    padding-right: 10px;
}

.quickButton button {
    height: 29px;
    width: 62px;
    background-color: rgba(0, 0, 0, 0.1);
    color: white;
    border-color: white;
    box-shadow: none;
    cursor: pointer;
    border: thin solid gray;
    border-width: 1px;
}

.wideButton button {
    width: 144px;
}

@media (max-width: 755px) {
    .dateRangePicker {
        justify-content: space-between;
    }

    .quickButton {
        padding-left: 0px;
        padding-right: 0px;
    }

    .quickButton button {
        width: 62px;
    }

    .hideMobile {
        display: none;
    }

    .wideButton button {
        width: 85px;
    }
}

.quickButton > button:hover {
    background-color: rgb(35, 37, 46);
}
